// Images file for 'downpaymentprogramsurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Checkmark from '../../../../src/images/checkmark-white.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import SliderArrows from '../../../../src/images/slider-arrows.png';

export default {
  Favicon,
  Checkmark,
  Dropdown,
  SliderArrows,
};
